import { NgModule } from "@angular/core";
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';

import { SidebarComponent } from "./sidebar.component";

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    SharedModule,
    RouterModule ],
  exports: [SidebarComponent],
  providers: []
})
export class SidebarModule {}
