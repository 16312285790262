import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from './components/shared-components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

const SharedModules = [
  CommonModule,
  RouterModule,
  SharedComponentsModule,
  PipesModule,
  FormsModule
];

@NgModule({
  imports: [
    SharedModules
  ],
  exports: [
    SharedModules
  ],
  declarations: [],
  providers: [],
})
export class SharedModule { }
