import { Component } from '@angular/core';

@Component({
    selector: 'mag-header',
    templateUrl: './header.component.html',
    host: {
        class: 'mag-header',
    }
})

export class HeaderComponent {}
