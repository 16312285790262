import { NgModule } from "@angular/core";
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from "./header.component";

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [HeaderComponent],
  providers: []
})
export class HeaderModule {}
