import { NgModule } from '@angular/core';

import { CountToArrayPipe } from './count-to-array.pipe';
import { ClassesJoinTrimPipe } from './classes-join-trim.pipe';


@NgModule({
  declarations:[
    CountToArrayPipe,
    ClassesJoinTrimPipe
  ],
  imports:[],
  exports:[
    CountToArrayPipe,
    ClassesJoinTrimPipe
  ]
})

export class PipesModule{}