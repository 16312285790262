import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public title = 'ng-magma';
  public themeClass = 'm-theme--lavavein';
  public themeClasses = [
    'm-theme--lavavein',
    'm-theme--ronBurgandy',
    'm-theme--dark',
    'm-theme--material',
    'm-theme--reset',
    'm-theme--none'
  ];

  public url: string;
  public urlClass: string;
  public previousUrl: string

  public isHome: boolean;
  public isDocs: boolean;

  public isDrawerOpen: boolean = false;

  constructor(
    @Inject(DOCUMENT) document,
    private _renderer: Renderer2,
    private _router: Router,
  ) {
  }

  ngOnInit() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects.slice(1);
        this.isHome = this.url === 'home';
        this.isDocs = this.url.startsWith('docs');
        if (this.urlClass) {
          this._renderer.removeClass(document.body, this.urlClass);
        }
        this.makeUrlClass(this.url);
        if (this.urlClass) {
          this._renderer.addClass(document.body, this.urlClass);
        }
      }
    });
  }

  public makeUrlClass(url: string) {
    this.urlClass = url.replace(/\//g, '-');
    this.urlClass = 'm-url--'.concat(this.urlClass);
  }

  public toggleTheme() {
    this._renderer.removeClass(document.body, this.themeClass);
    let randomThemeNumber = Math.floor(Math.random() * Math.floor(this.themeClasses.length));
    this.themeClass = this.themeClasses[randomThemeNumber];
    this._renderer.addClass(document.body, this.themeClass);
  }

  public toggleDrawerDemo() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }
}
