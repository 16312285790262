import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { HeaderModule } from './header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    SidebarModule
  ],
  exports: [
    HeaderModule,
    SidebarModule
  ],
  providers: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('Core Module Already Loaded.');
    }
  }
}
