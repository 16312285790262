import {Pipe,PipeTransform} from "@angular/core";

@Pipe({
  name: 'countToArray'
})
export class CountToArrayPipe implements PipeTransform {
  transform(length: number, offset: number = 1): number[] {
    if (!length) {
      return [];
    }
    const array = [];
    for (let n = 0; n < length; ++n) {
      array.push(offset + n);
    }
    return array;
  }
}