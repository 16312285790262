import {Pipe,PipeTransform} from "@angular/core";

@Pipe({
  name: 'classesJoinTrim'
})
export class ClassesJoinTrimPipe implements PipeTransform {
  transform(classes: string[]): string {
    if (classes && classes.length) {
      return classes.filter(this.isNotNull).join(' ').trim();
    } else {
      return null;
    }
  }
  public isNotNull(element) { 
    return (element !== null);
  }
}