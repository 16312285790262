import { Component } from '@angular/core';

@Component({
    selector: 'mag-sidebar',
    templateUrl: './sidebar.component.html',
    host: {
        class: 'mag-sidebar sidebar',
    }
})

export class SidebarComponent {
  // public isExpanded = false;
  //
  // public toggleSidebarExpanded() {
  //   this.isExpanded = !this.isExpanded
  // }
}
