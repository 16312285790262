import { Component, OnInit } from '@angular/core';
import { IBreadcrumb } from '../../interfaces/breadcrumb.interface';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'mag-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  host: {
    class: 'mag-breadcrumbs'
  }
})
export class BreadcrumbsComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      // distinctUntilChanged(),
    ).subscribe(() => {
      // this.breadcrumbs = this.buildBreadCrumb(this._activatedRoute.root);
      this.breadcrumbs = this.buildBreadCrumb(this._activatedRoute.root);
    });
  }

  ngOnInit() {
 
  }

  public buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []) {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : route.firstChild.routeConfig.path;
    // let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const homeCrumb: IBreadcrumb = {
      label: 'Home',
      url: '/home'
    }

    const breadcrumb: IBreadcrumb = {
        label: label,
        url: nextUrl,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return nextUrl !== homeCrumb.url ? [homeCrumb, ...newBreadcrumbs] : [];
    // return newBreadcrumbs;
  }
}
